// Base
.icon {
  display: inline-block;
  width: $font-size-base;
  height: $font-size-base;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

a[href*="mailto:"],
a[href*="tel:"] {
  white-space: nowrap;
}

html {
  font-size: 16px;

  // @include media-breakpoint-down(xl) {
  //   font-size: 14px;
  // }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  box-shadow: none !important;

  &-primary {
    color: #fff;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }

    &:disabled,
    &.disabled {
      color: rgba(#fff, .85);
    }
  }

  &-light {
    color: $gray-700;
  }

  &-link {
    padding: 0;
    letter-spacing: 0;
    font-size: $input-font-size;
    border: none;

    &:hover {
      text-decoration: none;
    }
  }

  &-danger {
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
    }
  }

  &-outline-primary {
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: #1479ff;
    }
  }

  &-sm {
    font-size: 11px;
  }
}

.bg-light {
  background-color: $gray-10 !important;
}

.page-item + .page-item {
  margin-left: 0.5rem;
}

.page-link {
  font-size: $pagination-font-size;
  line-height: calc(#{$pagination-size} - 2px);
  font-weight: $pagination-font-weight;
  width: $pagination-size;
  height: $pagination-size;
  text-align: center;
  border-radius: $pagination-border-radius;

  .icon {
    width: $pagination-icon-size;
    height: $pagination-icon-size;
  }
}

.form {
  &-label {
    line-height: $form-label-line-height;
  }

  &-check-input:checked[type=checkbox] {
    background-size: 13px 13px;
  }

  &-check-input[type="radio"] {
    background-size: 0.8125rem;
  }

  &-check-input[type=radio] {
    border-radius: $form-check-input-border-radius;
  }

  &-check-input:checked[type=radio] {
    background-image: escape-svg($form-check-input-checked-bg-image);
  }

  &-check-label {
    font-size: $input-font-size;
    line-height: $input-line-height;
    font-weight: $form-label-font-weight;

    .fw-normal & {
      font-weight: normal;
    }
  }

  &-control[type=file] {
    border: none;
    box-shadow: none;
  }

  .form-control:disabled,
  .form-control[readonly],
  .form-control.readonly,
  .form-select:disabled,
  .form-select[readonly],
  .form-select.readonly {
    background-color: transparent;
    border-color: transparent;
    padding-left: .75rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: 500;
    font-size: .875rem;
  }

  .form-select:disabled,
  .form-select[readonly],
  .form-select.readonly {
    background-image: none;
  }

  textarea.form-control:disabled,
  textarea.form-control[readonly],
  textarea.form-control.readonly {
    resize: none;
    height: auto !important;
  }

  .is-valid .form-control,
  .form-control.is-valid,
  .is-invalid .form-control,
  .form-control.is-invalid,
  .was-validated .form-control:valid,
  .was-validated .form-control:invalid {
    background-image: none;
    padding-right: $input-padding-x;
    border-color: $gray-300;
    box-shadow: none !important;
  }

  &-select.is-valid,
  &-select.is-invalid {
    background-image: escape-svg($form-select-indicator) !important;
    padding-right: 3.5625rem !important;
  }

  .is-valid,
  .is-invalid {
    &~.icon {
      width: 1.25rem;
      height: 1.25rem;
      right: -1.375rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include media-breakpoint-down(lg) {
        right: -1.375rem;
      }
    }

    @include media-breakpoint-down(lg) {
      // padding-right: $input-padding-x * 2;
    }
  }


  .is-valid::after,
  .is-invalid::after {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: -2rem;
    top: .9375rem;

    @include media-breakpoint-down(lg) {
      right: 0;
    }

    &-control:focus {
      box-shadow: none !important;
    }
  }

  .table .form-control.is-valid {
    border-color: $success !important;
  }

  .table .form-control.is-invalid {
    border-color: $danger !important;
  }

  .table .invalid-feedback {
    font-size: 8px;
    line-height: 12px;
  }

  .is-valid::after {
    background-image: $form-feedback-icon-valid;
  }

  .is-invalid::after {
    background-image: $form-feedback-icon-invalid;
  }

  .invalid-feedback {
    text-align: left;
    display: block;
  }
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: inherit;
}

.container {
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.table {
  th {
    font-size: $input-font-size;
    line-height: $input-line-height;
    font-weight: $form-label-font-weight;
    vertical-align: middle;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;

    & > div {
      border-left: 1px solid $gray-300;
      padding-left: $table-cell-padding-x;
      padding-right: $table-cell-padding-x;
    }

    &:first-child {
      div {
        border-left: none;
        padding-left: 0;
      }
    }
  }

  td {
    font-size: $table-font-size;
    line-height: $table-line-height;
    height: 2rem;
    vertical-align: middle;
    padding-right: 0;

    & > div { /* stylelint-disable-line no-descending-specificity */
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.v-top {
  td {
    vertical-align: top;

    div {
      line-height: 1.625rem;
    }
  }
}

.form-check-label,
.dropdown-item {
  cursor: pointer;
}

.dropdown-menu li + li {
  border-top: 1px solid $gray-300;
}

.card {
  position: relative;
  transition: $transition-base;

  &-title {
    font-size: $input-font-size;
    line-height: $input-line-height;
    font-weight: $form-label-font-weight;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &:hover,
  &:focus {
    background: #fff;
    border-color: #fff;
    box-shadow: $box-shadow-lg;
    z-index: 5;

    .card-title a {
      color: $blue;
    }
  }
}

.card-text {
  font-size: $small-font-size;
  line-height: $small-line-height;
}

.small,
small {
  font-size: $small-font-size;
  line-height: $small-line-height;
}

.mh-auto {
  min-height: auto !important;
}

.fs-7 {
  font-size: .75rem;
  line-height: 1.2;
}

.fw-600 {
  font-weight: $font-weight-semibold;
}

.fw-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.375rem;
  --ReactInputVerificationCode-itemHeight: 2.375rem;
  --ReactInputVerificationCode-itemSpacing: 10px;
  width: 100%;
  max-width: 100%;
  margin-top: var(--bs-gutter-y);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactInputVerificationCode__container{


}
.ReactInputVerificationCode__item {
  border: 1px solid #bac8db !important;
  border-radius: 0.5rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  text-align: center !important;
}
.ReactInputVerificationCode__item is-active{
  box-shadow: inset 0 0 0 2px #222;
}
.tableLinePoWo{
  height: 70px;
  padding-top: 8px;
}
.bids-column {
  width: 5% !important;
  div{
    text-align: center;
    a {
      text-align: center;
    }
  }
}
.date-column {
  width: 20% !important;
  min-width: 180px !important;
  margin-left: 15px !important;
  white-space: nowrap !important;
  @include media-breakpoint-down(xxl) {
    width: 20% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    div {
      white-space: nowrap !important;
    }

  }
}
.visibility-column {
  width: 10% !important;
}
.view-column {
  width: 13% !important;
}
.tender-column {
  width: 15% !important;
}
