// Variables

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

$font-family-sans-serif: 'Poppins', sans-serif;

$enable-rfs: false;
$enable-negative-margins: true;
$enable-shadows: true;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.75,
  10: $spacer * .625,
  12: $spacer * .75,
  14: $spacer * .875,
  20: $spacer * 1.25,
  30: $spacer * 1.875,
  36: $spacer * 2.25,
  40: $spacer * 2.5
);

$gray-10:         #f8fbff;
$gray-100:        #ebf1fa;
$gray-200:        #b6c3d3;
$gray-300:        #bac8db;
$gray-400:        #213a5c;
$gray-500:        #93a6bf;
$gray-600:        #e7edf5;
$gray-700:        #565f6a;
$gray-800:        #364a58;
$gray-50:         #F2F7FC;
$blue:            #1479ff;
$red:             #ff4545;
$red-dark:        #dc0000;
$green:           #27ae60;
$orange:          #f6ab60;
$white:           #fff;

$primary:         $blue;
$success:         $green;
$warning:         $orange;
$danger:          $red;
$danger-dark:     $red-dark;
$light:           $gray-100;
$light-50:        $gray-50;
$secondary:       $gray-300;

$theme-colors: (
  "primary":           $primary,
  "success":           $success,
  "warning":           $warning,
  "danger":            $danger,
  "light":             $light,
  "light-50":          $light-50,
  "danger-dark":       $danger-dark,
  "secondary":         $secondary
);

$box-shadow:                        0 5px 1.875rem rgba(182, 195, 211, .6);
$box-shadow-lg:                     0 1.25rem 3.75rem rgba(182, 195, 211, .5);

$border-radius-sm:                  .5rem;
$border-radius-lg:                  .75rem;

$h1-font-size:                      1.875rem;
$h2-font-size:                      1.73rem;
$h5-font-size:                      1.125rem;

$font-weight-bolder:                800;
$font-weight-semibold:              600;

$body-color:                        #274772;
$body-bg:                           #f3f8ff;

$input-font-size:                   .9375rem;
$input-line-height:                 1.375;
$form-label-font-size:              .9375rem;
$form-label-line-height:            1.375;
$input-padding-x:                   1.1875rem;
$input-padding-y:                   .9375rem;
$input-bg:                          transparent;
$input-border-color:                $gray-300;
$input-border-radius:               .5rem;
$input-placeholder-color:           $gray-300;
$input-focus-box-shadow:            none;
$input-focus-border-color:          $gray-300;
$form-label-font-weight:            600;
$input-box-shadow:                  none;
$form-label-margin-bottom:          0;

$navbar-light-color: #fff;

$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;


$form-feedback-icon-valid-color:        $gray-300;
$form-feedback-icon-valid:              url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99998 0C4.47715 0 0 4.47715 0 9.99998C0 15.5228 4.47715 20 9.99998 20C15.5228 20 20 15.5228 20 9.99998C19.9941 4.47961 15.5204 .00590122 9.99998 0ZM9.99998 18.5714C5.26611 18.5714 1.42856 14.7338 1.42856 9.99998C1.42856 5.26611 5.26611 1.42856 9.99998 1.42856C14.7338 1.42856 18.5714 5.26611 18.5714 9.99998C18.5663 14.7318 14.7318 18.5663 9.99998 18.5714Z' fill='%2327AE60'/%3E%3Cpath d='M15.4873 5.92366C15.2105 5.65631 14.7717 5.65631 14.4949 5.92366L7.85706 12.5615L5.5049 10.2093C5.23085 9.92557 4.77863 9.91775 4.49491 10.1918C4.21115 10.4659 4.20332 10.9181 4.47738 11.2018C4.48311 11.2077 4.48897 11.2136 4.49491 11.2193L7.35207 14.0765C7.63097 14.3553 8.08315 14.3553 8.36205 14.0765L15.5049 6.93361C15.779 6.64989 15.7711 6.19771 15.4873 5.92366Z' fill='%2327AE60'/%3E%3C/svg%3E%0A");
$form-feedback-icon-invalid-color:      $gray-300;
$form-feedback-icon-invalid:            url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z' stroke='%23FF4545' stroke-width='1.66667'/%3E%3Cpath d='M10.6667 7H11.3333L11 11.8333L10.6667 7Z' stroke='%23FF4545' stroke-width='1.66667' stroke-linejoin='round'/%3E%3Cpath d='M11 15.6667C11.5523 15.6667 12 15.2189 12 14.6667C12 14.1144 11.5523 13.6667 11 13.6667C10.4477 13.6667 10 14.1144 10 14.6667C10 15.2189 10.4477 15.6667 11 15.6667Z' fill='%23FF4545'/%3E%3C/svg%3E%0A");

$form-check-input-width:                1.3125em;
$form-check-min-height:                 1.3125em;
$form-check-input-border-radius:        .5rem;
$form-check-padding-start:              $form-check-input-width + .6875em;
$form-check-input-border:               1px solid $gray-300;
$form-check-input-checked-border-color: $gray-300;
$form-check-input-checked-color:        #fff;
$form-check-input-checked-bg-color:     #fff;
$form-check-input-checked-bg-image:     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'><rect width='13' height='13' rx='4' fill='#{$blue}'/></svg>");
$form-check-radio-checked-bg-image:     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 9'><circle cx='4.5' cy='4.5' r='4.5' fill='#{$blue}'/></svg>");
$form-select-focus-box-shadow:          none;
$form-select-box-shadow:                none;
$form-select-border-radius:             .5rem;


$form-select-bg-position:           right $input-padding-x center;
$form-select-bg-size:               9px 9px;
$form-select-indicator-color:       $gray-600;
$form-select-indicator:             url("data:image/svg+xml,<svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'><mask id='path-1-outside-1_100_628' maskUnits='userSpaceOnUse' x='-0.698172' y='-6.41421' width='16.9706' height='16.9706' fill='black'><rect fill='white' x='-0.698172' y='-6.41421' width='16.9706' height='16.9706'/><path fill-rule='evenodd' clip-rule='evenodd' d='M1.90506 0.88205L1.00029 1.78682L7.78711 8.57364L14.5739 1.78682L13.6692 0.88205L7.78711 6.7641L1.90506 0.88205Z'/></mask><path fill-rule='evenodd' clip-rule='evenodd' d='M1.90506 0.88205L1.00029 1.78682L7.78711 8.57364L14.5739 1.78682L13.6692 0.88205L7.78711 6.7641L1.90506 0.88205Z' fill='#{$body-color}'/><path d='M1.00029 1.78682L0.646734 1.43327L0.293181 1.78682L0.646734 2.14038L1.00029 1.78682ZM1.90506 0.88205L2.25861 0.528497L1.90506 0.174944L1.55151 0.528497L1.90506 0.88205ZM7.78711 8.57364L7.43356 8.9272L7.78711 9.28075L8.14066 8.9272L7.78711 8.57364ZM14.5739 1.78682L14.9275 2.14038L15.281 1.78682L14.9275 1.43327L14.5739 1.78682ZM13.6692 0.88205L14.0227 0.528497L13.6692 0.174944L13.3156 0.528497L13.6692 0.88205ZM7.78711 6.7641L7.43356 7.11765L7.78711 7.47121L8.14066 7.11765L7.78711 6.7641ZM1.35384 2.14038L2.25861 1.2356L1.55151 0.528497L0.646734 1.43327L1.35384 2.14038ZM8.14066 8.22009L1.35384 1.43327L0.646734 2.14038L7.43356 8.9272L8.14066 8.22009ZM14.2204 1.43327L7.43356 8.22009L8.14066 8.9272L14.9275 2.14038L14.2204 1.43327ZM13.3156 1.2356L14.2204 2.14038L14.9275 1.43327L14.0227 0.528497L13.3156 1.2356ZM13.3156 0.528497L7.43356 6.41055L8.14066 7.11765L14.0227 1.2356L13.3156 0.528497ZM8.14066 6.41055L2.25861 0.528497L1.55151 1.2356L7.43356 7.11765L8.14066 6.41055Z' fill='#{$body-color}' mask='url(#path-1-outside-1_100_628)'/></svg>");



$btn-font-size:                     1rem;
$btn-line-height:                   1.5rem;
$btn-font-weight:                   700;
$btn-border-radius:                 $spacer/2;
$btn-padding-y:                     .8125rem;
$btn-padding-x:                     .9375rem;
$btn-link-color:                    $body-color;
$btn-focus-box-shadow:              none;
$btn-active-box-shadow:             none;
$btn-box-shadow:                    none;
$input-btn-focus-box-shadow:        none;

$pagination-color:                  $gray-500;
$pagination-bg:                     transparent;
$pagination-padding-x:              .125rem;
$pagination-padding-y:              0;
$pagination-border-color:           $gray-600;
$pagination-border-radius:          .25rem;
$pagination-font-size:              .875rem;
$pagination-line-height:            1.25rem;
$pagination-size:                   2rem;
$pagination-font-weight:            600;
$pagination-icon-size:              .75rem;
$pagination-disabled-color:         $gray-500;
$pagination-disabled-bg:            #fff;
$pagination-disabled-border-color:  $gray-500;
$pagination-hover-color:            $gray-800;
$pagination-hover-bg:               #fff;
$pagination-hover-border-color:     $gray-400;
$pagination-active-color:           $gray-800;
$pagination-active-bg:              #fff;
$pagination-active-border-color:    $gray-800;


$badge-font-size:                   1em;
$badge-padding-y:                   0;
$badge-padding-x:                   .3125em;
$badge-border-radius:               50%;
$offcanvas-box-shadow:              $box-shadow-lg;

$small-font-size:                   .875rem;
$small-line-height:                 1.3125rem;

$table-th-font-weight:              600;
$table-cell-padding-y:              0;
$table-cell-padding-x:              .5rem;
$table-striped-bg:                  #f7faff;
$table-border-width:                0;
$table-font-size:                   $small-font-size;
$table-line-height:                 $small-line-height;

$dropdown-border-width:             0;
$dropdown-padding-y:                0;
$dropdown-spacer:                   0;
$dropdown-box-shadow:               $box-shadow-lg;
$dropdown-border-radius:            .5rem;
$dropdown-item-padding-y:           $spacer * .25;
$dropdown-item-padding-x:           $spacer * .75;
$dropdown-link-hover-color:         #fff;
$dropdown-link-hover-bg:            $blue;
$dropdown-link-color:               $body-color;

$card-border-color:                 rgba($gray-300, .5);
$card-spacer-y:                     $spacer * .7;
$card-spacer-x:                     $spacer;
$card-title-spacer-y:               0;
$card-border-radius:                .5rem;
$card-bg:                           transparent;

$modal-content-border-radius:       $border-radius-lg;
$modal-content-box-shadow-sm-up:    $box-shadow-lg;
$modal-content-border-width:        0;
$modal-header-padding-y:            .875rem;
$modal-inner-padding:               $spacer * 1.875;
$modal-md:                          544px;
$modal-backdrop-bg:                 #000;
$modal-backdrop-opacity:            .1;

$progress-height:                   .5rem;
$progress-bg:                       #e7edf5;

$btn-close-width:                   .875em;
$btn-close-color:                   #bac8db;
$btn-close-bg:                      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}' width='14' height='14'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-opacity:                 1;
$btn-close-focus-opacity:           .5;

$tooltip-color:                     $body-color;
$tooltip-bg:                        $gray-100;
$tooltip-border-radius:             $border-radius-sm;
$tooltip-padding-y:                 $spacer * .5;
$tooltip-padding-x:                 $spacer * .75;

$form-feedback-font-size: 10px;

$carousel-indicator-width:           13px !default;
$carousel-indicator-height:          13px !default;
$carousel-indicator-hit-area-height: 30px !default;
$carousel-indicator-spacer:          23px !default;
$carousel-indicator-opacity:         1 !default;
$carousel-indicator-active-bg:       $primary !default;
$carousel-indicator-active-opacity:  1 !default;
$carousel-indicator-bg:       #E4E8EB !default;

$carousel-control-icon-width:        2rem !default;

// $carousel-dark-indicator-active-bg:  $black !default;
// $carousel-dark-caption-color:        $black !default;
// $carousel-dark-control-icon-filter:  invert(1) grayscale(100) !default;